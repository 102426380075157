@charset "UTF-8";

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

/*============================
Body
============================*/

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

html,
div {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans JP", "游ゴシック", "Yu Gothic", YuGothic,
    "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "Noto Sans Japanese",
    Meiryo, sans-serif;
  color: #111111;
}

/* スクロールバーの有無によるズレ対策 */
html {
  overflow-x: hidden;
  overflow-y: scroll;
}

body {
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 16px;
  background: #fff no-repeat top left;
}

/* for IE6 */
* html body {
  font-size: 75%;
}

/* for IE7 */
*:first-child + html body {
  font-size: 75%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
dt,
dd {
  margin: 0;
  padding: 0;
  line-height: 1.8em;
  font-size: 12px;
  font-weight: 500;
}

ul,
ol,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  width: 100%;
  margin: 0;
  padding: 0;
  vertical-align: bottom;
}

a img {
  border: none;
}

/* a:link, a:visited {
  color: #111111;
  text-decoration: none;
  outline : 0;
  transition: 0.3s;
} */

a:hover {
  /* opacity: 0.8; */
  transition: 0.3s;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.clear {
  clear: both;
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

em,
i {
  font-style: normal;
}

/*============================
COMMON
============================*/

.disnon {
  display: none;
}
.disblo {
  display: block;
}

.f_noto {
  font-family: "Noto Sans JP", sans-serif !important;
}
.f_ubu {
  font-family: "Ubuntu", sans-serif !important;
}

@media (min-width: 821px) {
  .visible_pc {
    display: block;
  }
  .visible_sp {
    display: none;
  }

  .display_pc {
    display: inline;
  }
  .display_sp {
    display: none;
  }
}

@media (max-width: 820px) {
  .visible_sp {
    display: block;
  }
  .visible_pc {
    display: none;
  }
  .hidden_sp {
    display: none;
  }

  .display_pc {
    display: none;
  }
  .display_sp {
    display: inline;
  }
}

/*============================
FADE
============================*/
/* その場で */
.fadeIn {
  animation-name: fadeInAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}
@keyframes fadeInAnime {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
/* 下から */
.fadeUp {
  animation-name: fadeUpAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
}
@keyframes fadeUpAnime {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* 上から */
.fadeDown {
  animation-name: fadeDownAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
}
@keyframes fadeDownAnime {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* 左から */
.fadeLeft {
  animation-name: fadeLeftAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
}
@keyframes fadeLeftAnime {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
/* 右から */
.fadeRight {
  animation-name: fadeRightAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
}
@keyframes fadeRightAnime {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.fadeUpTrigger {
  opacity: 0;
}

/*============================
MainContents
============================*/
.MainContents {
  padding-top: 80px;
  padding-bottom: 200px;
}

/*============================
PageTitle
============================*/
.PageTitle {
  padding: 80px 0;
  background-color: #ff3f33;
  color: #ffffff;
}
.PageTitle p.en {
  font-size: 62px;
  font-weight: bold;
  letter-spacing: 0.1em;
}
.PageTitle h1 {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.1em;
}
/* SP */
@media (max-width: 820px) {
  .PageTitle {
    padding: 62px 0;
    background-color: #ff3f33;
    color: #ffffff;
  }
  .PageTitle p.en {
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0.1em;
  }
  .PageTitle h1 {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.1em;
  }
}

/*============================
共通 button
============================*/
a.common-buttom {
  display: inline-block;
}
a.common-buttom p.btn-text {
  width: 280px;
  display: block;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.1em;
  line-height: 80px;
  background-color: #111111;
  color: #ffffff;

  position: relative;
}
a.common-buttom p.btn-text:before {
  content: "";
  width: 8px;
  height: 8px;
  text-align: center;
  border-radius: 50%;
  line-height: 8px;
  background: #ff3f33;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
a p.article-buttom {
}
a p.article-buttom svg {
  display: inline-block;
  vertical-align: bottom;
}
.solution p.article-buttom,
.solution p.article-buttom svg {
  color: #1a9d4e;
  fill: #1a9d4e;
}
.dx p.article-buttom,
.dx p.article-buttom svg {
  color: #fc0e92;
  fill: #fc0e92;
}
.free p.article-buttom,
.free p.article-buttom svg {
  color: #14a9e1;
  fill: #14a9e1;
}
a:hover p.article-buttom,
a:hover p.article-buttom svg {
  color: #ffffff;
  fill: #ffffff;
}

input.Form-Btn {
  width: 280px;
  display: block;
  border-radius: 4px;
  border: none;
  outline: none;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.15em;
  background: #ff3f33;
  color: #fff;
  cursor: pointer;
}

input.Form-Btn.red {
  background: #ff3f33;
  color: #fff;
}

input.Form-Btn.gray {
  background: #dbdbdb;
  color: #111111;
}

input.Form-Btn.white {
  border: 1px solid #ff3f33;
  background: #fff;
  color: #ff3f33;
}

footer {
  border-top: solid #111111;
}

/*============================
CTA
============================*/

#CTA {
  width: auto;
}

#CTA a {
  display: block;
}

#CTA a .bg {
  min-height: 80vh;
  animation: bgchange 20s ease infinite;

  position: relative;
}

#CTA a .bg p.cta-title {
  font-size: 80px;
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 0.2em;

  color: #ffffff;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

#CTA a .bg p.cta-title span {
  display: block;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

#CTA a .bg p.cta-title:hover {
  color: #111111;
  transition: 0.5s;
}

@media (max-width: 820px) {
  #CTA a .bg {
    min-height: 50vh;
  }
  #CTA a .bg p.cta-title {
    font-size: 40px;
  }
  #CTA a .bg p.cta-title span {
    font-size: 14px;
  }
}

@keyframes bgchange {
  0% {
    background: #ff3f33;
  }
  25% {
    background: #fdb717;
  }
  50% {
    background: #fc0e92;
  }
  75% {
    background: #14a9e1;
  }
  90% {
    background: #1a9d4e;
  }
  100% {
    background: #ff3f33;
  }
}

/*============================
FOOTER
============================*/

#FOOTER {
  width: 100%;
  background-color: #303030;
  overflow: hidden;
  position: relative;
}

#FOOTER a {
  text-decoration: none;
}

.loop_wrap {
  display: flex;
  width: 100%;
  height: 200px;
  overflow: hidden;

  position: absolute;
  top: 0;
}

.loop_wrap div {
  flex: 0 0 auto;
  white-space: nowrap;
  font-size: 180px;
  font-family: "Ubuntu", sans-serif !important;
  font-weight: bold;
  line-height: 1;
  -webkit-text-stroke: 1px #ff3f33;
  text-stroke: 1px #ff3f33;
  color: #303030;
  overflow: hidden;
}

.loop_wrap div:nth-child(odd) {
  animation: loop 50s -25s linear infinite;
}

.loop_wrap div:nth-child(even) {
  animation: loop2 50s linear infinite;
}

@keyframes loop {
  0% {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes loop2 {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-200%);
  }
}

#FOOTER .FooterInner {
  width: 90%;
  margin: 0 auto;
  padding: 120px 0 80px 0;
}

#FOOTER .FooterInner .footer-top {
  display: flex;
  justify-content: space-between;
}

#FOOTER .FooterInner .footer-top .leftblock {
  width: 31.25%;
  z-index: 1;
}

#FOOTER .FooterInner .footer-top .leftblock a.footer-logo {
  display: block;
}

#FOOTER .FooterInner .footer-top .leftblock a.footer-logo img {
  width: 100%;
}

#FOOTER .FooterInner .footer-top .leftblock ul.link-list {
  width: auto;
  padding-top: 80px;
  display: flex;
  justify-content: space-between;
}

#FOOTER .FooterInner .footer-top .leftblock ul.link-list li {
  width: 42%;
}

#FOOTER .FooterInner .footer-top .leftblock ul.link-list li a {
  display: grid;
  width: auto;
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 8px;
  border: 3px solid #ff3f33;
  background-color: #ff3f33;
  color: #ffffff;
  align-items: center;
}

#FOOTER .FooterInner .footer-top .leftblock ul.link-list li:last-child a {
  background-color: #ffffff;
  color: #ff3f33;
}

#FOOTER .FooterInner .footer-top .leftblock ul.link-list li a p.text {
  font-weight: bold;
  letter-spacing: 0.5em;
  line-height: 1.4;
}

#FOOTER .FooterInner .footer-top .leftblock ul.link-list li a p.text img {
  width: 38%;
  margin: 0 auto;
}

#FOOTER .FooterInner .footer-top .leftblock ul.link-list li a p.text span {
  display: block;
  font-family: "Ubuntu", sans-serif;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0em;
}

#FOOTER
  .FooterInner
  .footer-top
  .leftblock
  ul.link-list
  li
  a
  p.text
  .footer-bigtext {
  display: block;
  font-family: "Ubuntu", sans-serif;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0em;
  padding-bottom: 10px;
}

#FOOTER .FooterInner .footer-top .rightblock {
  width: 48.43%;
}

#FOOTER .FooterInner .footer-top .rightblock nav.footer-nav {
}

#FOOTER .FooterInner .footer-top .rightblock nav.footer-nav ol.link-list {
}

#FOOTER
  .FooterInner
  .footer-top
  .rightblock
  nav.footer-nav
  ol.link-list
  li.link {
  padding-top: 40px;
}

#FOOTER
  .FooterInner
  .footer-top
  .rightblock
  nav.footer-nav
  ol.link-list
  li.link:first-child {
  padding-top: 0;
}

#FOOTER
  .FooterInner
  .footer-top
  .rightblock
  nav.footer-nav
  ol.link-list
  li.link
  a {
  display: block;
  padding: 20px 40px;
  padding-right: 80px;
  border-radius: 12px;
  background-color: #1a9d4e;

  position: relative;
}

#FOOTER
  .FooterInner
  .footer-top
  .rightblock
  nav.footer-nav
  ol.link-list
  li.link:nth-child(3)
  a {
  background-color: #fc0e92;
}
#FOOTER
  .FooterInner
  .footer-top
  .rightblock
  nav.footer-nav
  ol.link-list
  li.link:nth-child(4)
  a {
  background-color: #fdb717;
}
#FOOTER
  .FooterInner
  .footer-top
  .rightblock
  nav.footer-nav
  ol.link-list
  li.link:nth-child(5)
  a {
  background-color: #7171e5;
}
#FOOTER
  .FooterInner
  .footer-top
  .rightblock
  nav.footer-nav
  ol.link-list
  li.link:nth-child(2)
  a {
  background-color: #14a9e1;
}

#FOOTER
  .FooterInner
  .footer-top
  .rightblock
  nav.footer-nav
  ol.link-list
  li.link:nth-child(1)
  a {
  padding-bottom: 40px;
}

#FOOTER
  .FooterInner
  .footer-top
  .rightblock
  nav.footer-nav
  ol.link-list
  li.link
  a::after {
  content: "";
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #111111;
  background-image: url(./assets/images/arrow_forward_FILL0_wght400_GRAD0_opsz24.svg);
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%) translateX(-50%);
}

#FOOTER
  .FooterInner
  .footer-top
  .rightblock
  nav.footer-nav
  ol.link-list
  li.link
  a
  p.text {
  text-align: left;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 1.8;
  color: #111111;
}

#FOOTER
  .FooterInner
  .footer-top
  .rightblock
  nav.footer-nav
  ol.link-list
  li.link
  a
  p.text
  span {
  display: block;
  font-size: 12px;
  letter-spacing: 0.05em;
}

#FOOTER .FooterInner .footer-bottom {
  margin-top: 80px;
  padding-top: 80px;
  border-top: 1px solid #dbdbdb;

  display: flex;
  justify-content: space-between;
}

#FOOTER .FooterInner .footer-bottom nav.bottom-nav {
  width: 57.03%;
}

#FOOTER .FooterInner .footer-bottom nav.bottom-nav ol.link-list {
  text-align: left;
}

#FOOTER .FooterInner .footer-bottom nav.bottom-nav ol.link-list li.link {
  display: inline-block;
  margin-right: 16px;
  font-size: 12px;
  color: #dbdbdb;
}

#FOOTER .FooterInner .footer-bottom nav.bottom-nav ol.link-list li.link a {
  color: #dbdbdb;
}

#FOOTER .FooterInner .footer-bottom nav.bottom-nav ol.link-list li.link a svg {
  fill: #dbdbdb;
  height: 14px;
}

#FOOTER .FooterInner .footer-bottom ul.banner-list {
  width: 39.35%;
}

#FOOTER .FooterInner p.copyright {
  padding-top: 40px;
  font-size: 10px;
  color: #dbdbdb;
}

/* SP */

@media (max-width: 820px) {
  #FOOTER .FooterInner .footer-top {
    flex-wrap: wrap;
  }
  #FOOTER .FooterInner .footer-top .leftblock {
    width: 80%;
    margin: 0 auto;
  }

  #FOOTER
    .FooterInner
    .footer-top
    .leftblock
    ul.link-list
    li
    a
    p.text
    .footer-bigtext {
    font-size: 22px;
  }

  #FOOTER
    .FooterInner
    .footer-top
    .rightblock
    nav.footer-nav
    ol.link-list
    li.link
    a
    p.text {
    font-size: 22px;
  }

  #FOOTER .FooterInner .footer-top .rightblock {
    width: auto;
    padding-top: 80px;
  }

  #FOOTER .FooterInner .footer-bottom {
    display: block;
  }

  #FOOTER .FooterInner .footer-bottom nav.bottom-nav {
    width: auto;
  }

  #FOOTER .FooterInner .footer-bottom ul.banner-list {
    padding-top: 40px;
    width: auto;
  }
}

/*============================
ヘッダー
============================*/
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  z-index: 999;
  color: black;
  border-bottom: 1px solid #111111;
}

.headerMenu {
  margin-left: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
}

.headerLogo {
  width: 144px;
  margin-right: 20px;
}

.headerMenu a {
  text-decoration: none;
  color: black;
  margin: 0 20px;
}

.headerMenu a:hover {
  opacity: 0.3;
}

.headerLogin {
  margin-right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.headerLoginButton {
  transition: 0.1s;
}

.headerLoginButton:hover {
  opacity: 0.3;
}

.headerLoginButton img {
  width: 145px;
  display: block;
  margin: auto;
}

.headerSignupButton {
  transition: 0.1s;
}

.headerSignupButton:hover {
  opacity: 0.3;
}

.headerSignupButton img {
  width: 162px;
  display: block;
  margin: auto;
}

.headerUserInfo {
  margin-right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.headerUserInfo span {
  cursor: pointer;
}

.headerUserInfo .applyButton {
  text-decoration: none;
  color: white;
  border: none;
  font-family: "Ubuntu", sans-serif;
  border-radius: 10px;
  background-color: #ff3f33;
  cursor: pointer;
  transition: 0.1s;
  font-weight: bold;
  font-size: 20px;
  width: 145px;
  height: 52px;
}

.headerUserInfo .mypageButton {
  text-decoration: none;
  color: #ff3f33;
  border: 2px solid #ff3f33;
  font-family: "Ubuntu", sans-serif;
  border-radius: 10px;
  background-color: white;
  cursor: pointer;
  transition: 0.1s;
  font-weight: bold;
  font-size: 20px;
  width: 145px;
  height: 52px;
}

.headerUserInfo button:hover,
.headerUserInfo span:hover {
  opacity: 0.5;
}

@media screen and (max-width: 960px) {
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
  }

  .headerLogo {
    width: 144px;
    margin-right: 0px;
  }
  .headerMenu {
    margin-left: initial;
    width: 100%;
    text-align: center;
  }
  .headerMenu a {
    margin: 0;
  }
  .headerButton button {
    font-size: 30px;
    background: none;
    color: black;
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px 15px 5px 15px;
    margin-right: 20px;
  }
  .spMenu {
    position: fixed;
    top: 80px;
    width: 100%;
    z-index: 100;
    background-color: white;
    opacity: 0;
    border-top: 0.1px solid lightgray;
    border-bottom: 0.1px solid lightgray;
    overflow: hidden;
  }
  .spMenu.show {
    animation-name: fadeDownAnime;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    opacity: 0;
  }
  @keyframes fadeDownAnime {
    from {
      opacity: 0;
      transform: translateY(-100px);
    }
    to {
      opacity: 0.9;
      transform: translateY(0);
    }
  }
  .spMenu .headerSignupButton img {
    width: 100px;
  }
  .spMenu .headerLoginButton img {
    width: 100px;
  }
  .spMenu p {
    font-size: 16px;
    padding: 15px;
  }
  .spMenu a {
    color: #ff3f33;
  }
  .headermypageButton button,
  .headerapplyButton button {
    text-decoration: none;
    color: white;
    border: none;
    border-radius: 10px;
    background-color: #ff3f33;
    cursor: pointer;
    transition: 0.1s;
    font-weight: bold;
    font-size: 16px;
    width: 100px;
    height: 40px;
    font-family: "Ubuntu", sans-serif;
  }
  .headerUserInfo button:hover,
  .headerUserInfo span:hover {
    opacity: 0.5;
  }
}

/*============================
TOPページ
============================*/

#TOP {
  width: auto;
}

#TOP a {
  text-decoration: none;
}

#TOP h2.top-title {
  width: auto;
  font-family: "Ubuntu", sans-serif !important;
  font-size: 62px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

/*============================
keyvisual
============================*/

#TOP .keyvisual {
  width: 100%;
  /* height: calc(100vh - 80px); */
  margin-inline: auto;
  overflow: hidden; /* 画像がはみ出ないようにする */
  position: relative;
}

#TOP .keyvisual .titleblock {
  width: 100%;
  position: absolute;
  /* top: 50%; */
  bottom: calc(50% - 80px);
  z-index: 1;
}

#TOP .keyvisual .titleblock p.en_read {
  font-size: 4.5vw;
  font-family: "Ubuntu", sans-serif !important;
  font-weight: bold;
  letter-spacing: 0.05em;
  text-shadow: 0 1rem 2rem rgba(11, 11, 11, 0.8);
  color: #ffffff;
}

#TOP .keyvisual .titleblock h1.main_title {
  font-size: 5.5vw;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-shadow: 0 1rem 2rem rgba(11, 11, 11, 0.8);
  color: #ffffff;
}

#TOP .keyvisual .slider {
  width: 100%;
  /* min-height: 100vh; */
  margin-inline: auto;
  overflow: hidden; /* 画像がはみ出ないようにする */

  position: relative;
}

#TOP .keyvisual::after {
  content: "";
  display: block;

  width: 100%;
  height: 99.5%;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: radial-gradient(#111111 30%, transparent 31%),
    radial-gradient(#111111 30%, transparent 31%);
  background-size: 4px 4px;
  background-position: 0 0, 2px 2px;

  position: absolute;
  top: 0;
  right: 0;
}
#TOP .keyvisual .slider .slick-img {
  width: 100%;
  /* height: 100vw; */
  /*object-fit: cover;*/
}

@keyframes zoomUp {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.15); /* 拡大率 */
  }
}
#TOP .keyvisual .slider .add-animation {
  animation: zoomUp 10s linear 0s normal both;
}
/*============================
bannerContainer
============================*/

#TOP .bannerContainer {
  position: relative;
  bottom: 100px;
}

#TOP .bannerContainer .banner-slider {
  width: auto;
  margin: 0 8px;
}

#TOP .bannerContainer .banner-slider .slick-img {
  display: block;
  width: 95%;
}

#TOP .bannerContainer .banner-slider .slick-img img {
  object-fit: cover;
  border: 1px solid black;
}

#TOP .bannerContainer .banner-slider .mask-image img {
  object-fit: cover;
  border: 1px solid black;
  opacity: 0.5;
}

#TOP .bannerContainer .banner-slider .mask-image {
  background-color: lightgray;
}

/*============================
readContainer
============================*/

#TOP .readContainer {
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
  padding-top: 60px;
  padding-bottom: 160px;
}

#TOP .readContainer .read-text {
  width: 65.62%;
  margin: 0 0 0 auto;
  padding-top: 40px;
  text-align: left;
  font-size: 2.5vw;
  font-weight: bold;
  letter-spacing: 0.1em;
}
#TOP .readContainer .read-text:first-of-type {
  padding-top: 0;
}

/*============================
AboutContainer
============================*/

#TOP .AboutContainer {
  width: 100%;
  height: calc(100vh - 80px);

  display: flex;
  justify-content: center;
  align-items: center;
}

#TOP .AboutContainer .bg-inner {
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 80px 0;
  background-color: rgba(255, 255, 255, 0.9);
}

#TOP .AboutContainer .bg-inner .text {
  width: 90%;
  margin: 0 auto;
}

#TOP .AboutContainer .bg-inner .text p.read-text {
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.05em;
}

#TOP .AboutContainer .bg-inner .text p.text {
  padding-top: 40px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.05em;
}

/*============================
feautureContainer
============================*/

/* #TOP .feautureContainer {
} */

#TOP .feautureContainer .section {
  width: auto;
}

#TOP .feautureContainer .top-title {
  width: 90%;
  max-width: 1024px;
  margin: 80px auto 0px auto;
}

#TOP .feautureContainer p.text {
  font-size: 18px;
  letter-spacing: 0.05em;
  width: 90%;
  max-width: 1024px;
  margin: 0px auto 0px auto;
  padding: 80px 0;
  font-weight: 500;
}

#TOP .feautureContainer .section:nth-of-type(2n) {
  background-color: #fbfbfb;
}

#TOP .feautureContainer .section .bg-inner {
  width: 90%;
  max-width: 1024px;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
}

#TOP .feautureContainer .section .text-block {
  width: 48.43%;
  text-align: left;
}

#TOP .feautureContainer .section .text-block p.number {
  padding-left: 30px;
  font-size: 20px;
  font-family: "Ubuntu", sans-serif !important;
  font-weight: bold;
  letter-spacing: 0.1em;

  position: relative;
}
#TOP .feautureContainer .section .text-block p.number:before {
  content: "";
  width: 16px;
  height: 16px;
  text-align: center;
  font-size: 15px;
  border-radius: 50%;
  line-height: 16px;
  background: #1a9d4e;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
#TOP
  .feautureContainer
  .section.section:nth-of-type(2)
  .text-block
  p.number:before {
  background: #14a9e1;
}
#TOP
  .feautureContainer
  .section.section:nth-of-type(3)
  .text-block
  p.number:before {
  background: #fc0e92;
}
#TOP
  .feautureContainer
  .section.section:nth-of-type(4)
  .text-block
  p.number:before {
  background: #fdb717;
}
#TOP
  .feautureContainer
  .section.section:nth-of-type(5)
  .text-block
  p.number:before {
  background: #7171e5;
}

#TOP .feautureContainer .section .text-block h3.title {
  padding-top: 8px;
  font-size: 62px;
  font-weight: bold;
  line-height: 1.4;
  color: #1a9d4e;
}
#TOP .feautureContainer .section:nth-of-type(2) .text-block h3.title {
  color: #14a9e1;
}
#TOP .feautureContainer .section:nth-of-type(3) .text-block h3.title {
  color: #fc0e92;
}
#TOP .feautureContainer .section:nth-of-type(4) .text-block h3.title {
  color: #fdb717;
}
#TOP .feautureContainer .section:nth-of-type(5) .text-block h3.title {
  color: #7171e5;
}

#TOP .feautureContainer .section .text-block p.text {
  padding-top: 40px;
  font-size: 18px;
  letter-spacing: 0.05em;
}

#TOP .feautureContainer .section .image-block {
  width: 48.43%;
}

#TOP .feautureContainer .section:nth-of-type(2) .image-block img {
  border-radius: 28px;
  border: 20px solid #14a9e1;
}

#TOP .feautureContainer .section:nth-of-type(3) .image-block img {
  border-radius: 28px;
  border: 20px solid #fc0e92;
}
#TOP .feautureContainer .section:nth-of-type(5) .image-block img {
  border: 20px solid #7171e5;
  border-radius: 28px;
}

/*============================
projectContainer
============================*/

#TOP .projectContainer {
  padding: 80px 0;
  background-color: #fce2da;

  position: relative;
}

#TOP .projectContainer .project_loop_wrap {
  display: flex;
  width: 100%;
  height: 200px;
  overflow: hidden;

  position: absolute;
  top: 0;
}

#TOP .projectContainer .project_loop_wrap div {
  flex: 0 0 auto;
  white-space: nowrap;
  font-size: 180px;
  font-family: "Ubuntu", sans-serif !important;
  font-weight: bold;
  line-height: 1;
  -webkit-text-stroke: 1px #ff3f33;
  text-stroke: 1px #ff3f33;
  color: #fce2da;
  overflow: hidden;
}

#TOP .projectContainer .project_loop_wrap div:nth-child(odd) {
  animation: project_loop 50s -25s linear infinite;
}

#TOP .projectContainer .project_loop_wrap div:nth-child(even) {
  animation: project_loop2 50s linear infinite;
}

@keyframes project_loop {
  0% {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes project_loop2 {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-200%);
  }
}

#TOP .projectContainer .bg-inner .text-block {
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
  text-align: left;

  position: relative;
  z-index: 1;
}

#TOP .projectContainer .bg-inner .text-block p.jp-title {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

#TOP .projectContainer .bg-inner .text-block p.text {
  padding: 20px 0 40px 0;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.05em;
}

#TOP .projectContainer .bg-inner .priject-list .section-lineup {
  overflow: hidden;
  padding: 80px 0 120px 0;
}
#TOP .projectContainer .bg-inner .priject-list .section-lineup .inner {
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
}
#TOP .projectContainer .bg-inner .priject-list .section-lineup .lineup {
  position: relative;
}
#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .lineup-carousel
  li {
  text-align: center;
  margin-right: 24px;
}
#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .lineup-carousel
  img {
  display: block;
  width: 100%;
}
#TOP .projectContainer .bg-inner .priject-list .section-lineup .slick-list {
  overflow: visible;
}
#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .slick-nav-arrows {
  position: absolute;
  bottom: -80px;
  right: 0%;
}
#TOP .projectContainer .bg-inner .priject-list .section-lineup .slick-arrow {
}
#TOP .projectContainer .bg-inner .priject-list .section-lineup .slick-next {
  top: 110%;
  right: 5%;
}
#TOP .projectContainer .bg-inner .priject-list .section-lineup .slick-prev {
  top: 110%;
  left: 90%;
}
#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .slick-next:before,
#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .slick-prev:before {
  color: #111111;
}

#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .inner
  .lineup
  ul.lineup-carousel
  li {
  width: auto;
  border: 2px solid #111111;
  background-color: #ffffff;
}

#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .inner
  .lineup
  ul.lineup-carousel
  li
  article
  a.article-link {
  display: block;
}

#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .inner
  .lineup
  ul.lineup-carousel
  li
  article
  a.article-link
  .articleblock {
  width: 90%;
  margin: 0 auto;
  padding: 16px 0;
  text-align: left;
}

#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .inner
  .lineup
  ul.lineup-carousel
  li
  article
  a.article-link
  .articleblock
  h2.title {
  padding: 20px 0;
  font-size: 16px;
  letter-spacing: 0.1em;
  font-weight: bold;
}

#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .inner
  .lineup
  ul.lineup-carousel
  li
  article
  a.article-link
  .articleblock
  .logo
  img {
  width: 70%;
  max-height: 100px;
  height: 100px;
  margin: 0 auto;
  object-fit: contain;
}

#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .inner
  .lineup
  ul.lineup-carousel
  li
  article
  a.article-link
  .articleblock
  ul.tag {
  padding: 0 0 16px 0;
}

#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .inner
  .lineup
  ul.lineup-carousel
  li
  article
  a.article-link
  .articleblock
  ul.tag
  li {
  display: inline-block;
  margin: 2px;
  padding: 8px;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.1em;
  border: 1px solid;
  background-color: #ffffff;
  position: relative;
}

#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .inner
  .lineup
  ul.lineup-carousel
  li
  article
  a.article-link
  .articleblock
  ul.tag
  li
  svg {
  height: 16px;
  margin-right: 0;
  vertical-align: bottom;
}

#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .inner
  .lineup
  ul.lineup-carousel
  li
  article.solution
  a.article-link
  .articleblock
  ul.tag
  li.tag-thema {
  color: #1a9d4e;
}
#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .inner
  .lineup
  ul.lineup-carousel
  li
  article.solution
  a.article-link
  .articleblock
  ul.tag
  li.tag-thema
  svg {
  fill: #1a9d4e;
}
#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .inner
  .lineup
  ul.lineup-carousel
  li
  article.dx
  a.article-link
  .articleblock
  ul.tag
  li.tag-thema {
  color: #fc0e92;
}
#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .inner
  .lineup
  ul.lineup-carousel
  li
  article.dx
  a.article-link
  .articleblock
  ul.tag
  li.tag-thema
  svg {
  fill: #fc0e92;
}
#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .inner
  .lineup
  ul.lineup-carousel
  li
  article.free
  a.article-link
  .articleblock
  ul.tag
  li.tag-thema {
  color: #14a9e1;
}
#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .inner
  .lineup
  ul.lineup-carousel
  li
  article.free
  a.article-link
  .articleblock
  ul.tag
  li.tag-thema
  svg {
  fill: #14a9e1;
}

#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .inner
  .lineup
  ul.lineup-carousel
  li
  article
  a.article-link
  .articleblock
  ul.tag
  li.tag-intention {
  color: #959595;
}

#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .inner
  .lineup
  ul.lineup-carousel
  li
  article
  a.article-link
  .articleblock
  ul.tag
  li.tag-intention
  svg {
  height: 16px;
  vertical-align: bottom;
  fill: #959595;
}

#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .inner
  .lineup
  p.text {
  padding-bottom: 16px;
  font-size: 12px;
  letter-spacing: 0.05em;
}

#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .inner
  .lineup
  p.buttom {
  padding: 8px;
  font-size: 14px;
  letter-spacing: 0.1em;
  line-height: 40px;
  border-radius: 4px;

  position: relative;
}

#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .inner
  .lineup
  p.buttom
  svg {
  position: absolute;
  right: 8px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .inner
  .lineup
  p.buttomttom {
  color: #ffffff;
}
p.buttomttom svg {
  display: inline-block;
  vertical-align: middle;
}
.solution p.buttom,
.solution p.buttom svg {
  background-color: #1a9d4e;
  color: #ffffff;
  fill: #ffffff;
}
.dx p.buttom,
.dx p.buttom svg {
  background-color: #fc0e92;
  color: #ffffff;
  fill: #ffffff;
}
.free p.buttom,
.free p.buttom svg {
  background-color: #14a9e1;
  color: #ffffff;
  fill: #ffffff;
}

#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .inner
  .lineup
  div.date {
  text-align: right;
  margin-top: 10px;
}

#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .inner
  .lineup
  p.date {
  font-size: 12px;
  letter-spacing: 0.1em;
  color: #959595;
}

#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .inner
  .lineup
  p.date.dead {
  display: inline-block;
  padding: 2px 8px;
  background-color: #959595;
  color: #ffffff;
}
.projectContainer .slick-slide > div {
  margin: 0 24px;
}
.projectContainer .slick-list {
  margin: 0 -24px;
}
#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .lineup-carousel
  li
  .disabled {
  opacity: 0.4;
  background-color: #959595;
}
#TOP
  .projectContainer
  .bg-inner
  .priject-list
  .section-lineup
  .lineup-carousel
  li
  .disabled
  a {
  cursor: not-allowed;
}

/*============================
flowContainer
============================*/

#TOP .flowContainer {
  background-repeat: repeat;
}
#TOP .flowContainer .bg-inner {
  padding: 160px 0 200px 0;
  backdrop-filter: blur(8px);
}

#TOP .flowContainer .bg-inner .flow-list {
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
  padding-top: 80px;

  display: flex;
  justify-content: space-between;
}

#TOP .flowContainer .bg-inner .flow-list .block {
  width: 23.75%;
}

#TOP .flowContainer .bg-inner .flow-list .block h3.title {
  padding-top: 20px;
  font-size: 18px;
  letter-spacing: 0.1em;
  font-weight: bold;
  color: #ff3f33;
}

#TOP .flowContainer .bg-inner p.kome {
  padding: 80px 0 20px 0;
  font-size: 16px;
  color: #959595;
}

/* SP */
@media (max-width: 820px) {
  #TOP .keyvisual .slider .slick-img {
  }
  #TOP h2.top-title {
    font-size: 40px;
  }

  #TOP .readContainer .read-text {
    font-size: 4.5vw;
  }
  #TOP .feautureContainer .top-title {
    font-size: 35px;
  }

  #TOP .feautureContainer .section .bg-inner {
    display: block;
  }

  #TOP .feautureContainer .section .text-block {
    width: auto;
  }

  #TOP .feautureContainer .section .text-block h3.title {
    font-size: 40px;
  }

  #TOP .feautureContainer .section .image-block {
    width: 80%;
    margin: 0 auto;
    padding-top: 40px;
  }

  #TOP .flowContainer .bg-inner .flow-list {
    display: block;
  }

  #TOP .flowContainer .bg-inner .flow-list .block {
    width: auto;
  }

  #TOP .flowContainer .bg-inner .flow-list .block img {
    width: 80%;
    margin: 0 auto;
  }

  #TOP .projectContainer .bg-inner .priject-list .section-lineup .slick-prev {
    left: 80%;
  }
}

/*============================
応募方法ページ
============================*/
#FLOW .registerContainer .bg-inner {
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 80px 0;
  border: 4px solid #111111;
  background-color: #ffffff;
}

#FLOW .registerContainer .bg-inner h2.title {
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

#FLOW .registerContainer .bg-inner ul.register-list {
  width: 90%;
  margin: 0 auto;
}

#FLOW .registerContainer .bg-inner ul.register-list li {
  padding-top: 60px;
}
#FLOW .registerContainer .bg-inner ul.register-list li:first-child {
  padding-top: 40px;
}

#FLOW .registerContainer .bg-inner ul.register-list li dl {
  display: flex;
  align-items: center;
}

#FLOW .registerContainer .bg-inner ul.register-list li dl dt {
}

#FLOW .registerContainer .bg-inner ul.register-list li dl dt img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid #ff3f33;
}

#FLOW .registerContainer .bg-inner ul.register-list li dl dd {
  margin-left: 40px;
}

#FLOW .registerContainer .bg-inner ul.register-list li dl dd p.titleText {
  text-align: left;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.1em;
  padding-bottom: 20px;
}

#FLOW .registerContainer .bg-inner ul.register-list li dl dd p.text {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

/*============================
FLOW ContentsContainer
============================*/

#FLOW .ContentsContainer {
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
  padding-top: 160px;
}

#FLOW .ContentsContainer section {
  margin-top: 120px;
  padding: 80px 40px;
  border-radius: 20px;

  display: flex;
  justify-content: space-between;
}
#FLOW .ContentsContainer section:first-of-type {
  margin: 0;
  background-color: #1a9d4e;
}
#FLOW .ContentsContainer section:nth-of-type(2) {
  background-color: #14a9e1;
}
#FLOW .ContentsContainer section:nth-of-type(3) {
  background-color: #fc0e92;
}
#FLOW .ContentsContainer section:nth-of-type(4) {
  background-color: #fdb717;
}
#FLOW .ContentsContainer section:nth-of-type(5) {
  background-color: #7171e5;
}
#FLOW .ContentsContainer section:nth-of-type(6) {
  background-color: #ff3f33;
}

#FLOW .ContentsContainer section .textblock {
  width: 58.4%;
  text-align: left;
  color: #ffffff;
}

#FLOW .ContentsContainer section .textblock p.number {
  padding-left: 30px;
  font-size: 20px;
  font-family: "Ubuntu", sans-serif !important;
  font-weight: bold;
  letter-spacing: 0.1em;

  position: relative;
}
#FLOW .ContentsContainer section .textblock p.number:before {
  content: "";
  width: 16px;
  height: 16px;
  text-align: center;
  font-size: 15px;
  border-radius: 50%;
  line-height: 16px;
  background: #ffffff;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#FLOW .ContentsContainer section .textblock h3.title {
  padding-top: 8px;
  font-size: 48px;
  font-weight: bold;
  line-height: 1.4;
}

#FLOW .ContentsContainer section .textblock p.text {
  padding-top: 40px;
  font-size: 18px;
  letter-spacing: 0.05em;
}

#FLOW .ContentsContainer section .imageblock {
  width: 37.7%;
}

#FLOW .ContentsContainer .lastblock {
  padding-top: 160px;
}

#FLOW .ContentsContainer h2.title {
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

#FLOW .ContentsContainer .imageblock {
  padding: 40px 0;
}

#FLOW .ContentsContainer p.text {
  font-size: 18px;
  letter-spacing: 0.1em;
  font-weight: bold;
}

@media (max-width: 820px) {
  #FLOW .registerContainer .bg-inner ul.register-list li dl {
    display: block;
  }
  #FLOW .registerContainer .bg-inner ul.register-list li dl dd {
    margin: 0;
    padding-top: 20px;
  }
  #FLOW .registerContainer .bg-inner ul.register-list li dl dd p.text {
    text-align: center;
  }

  #FLOW .registerContainer .bg-inner ul.register-list li dl dd p.titleText {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }

  #FLOW .ContentsContainer section {
    padding: 40px;
    display: block;
  }
  #FLOW .ContentsContainer section .textblock {
    width: auto;
  }
  #FLOW .ContentsContainer section .textblock h3.title {
    font-size: 38px;
  }
  #FLOW .ContentsContainer section .textblock p.text {
    padding: 0;
  }
  #FLOW .ContentsContainer section .imageblock {
    width: auto;
  }
}

/*============================
会員登録ページ
============================*/
.signupForm {
  padding: 80px;
}

.signupFormItemTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.signupFormItem {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.signupFormItem p {
  font-weight: bold;
  text-align: left;
  padding-bottom: 10px;
  font-size: 16px;
}

.signupFormRequiredText {
  background-color: #ff3f33;
  color: white;
  padding: 5px 5px 5px 5px;
  border-radius: 5px;
  margin-left: 20px;
  font-size: 12px;
}

.signupFormItem input[type="text"],
.signupFormItem input[type="mail"],
.signupFormItem input[type="password"] {
  padding: 10px;
  width: 250px;
  font-size: 16px;
  outline: none;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.signupFormItem input[type="checkbox"] {
  margin: 10px;
}

.signupFormItem input[type="text"]:focus,
.signupFormItem input[type="mail"]:focus,
.signupFormItem input[type="password"]:focus {
  outline: none;
  border: 1.5px solid #ff3f33;
  border-radius: 5px;
}

.signupFormItem button {
  text-decoration: none;
  color: white;
  padding: 15px;
  background-color: #ff3f33;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 50px;
  width: 150px;
  transition: 0.3s;
}

.signupFormItem button:hover {
  opacity: 0.3;
}

.signupFormItem button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.signupFormLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 100px;
}

.signupFormLogin a {
  border-radius: 5px;
  transition: 0.3s;
  color: black;
  font-size: 16px;
}

.signupFormLogin a:hover {
  opacity: 0.3;
}

.signupError {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  margin-bottom: 50px;
}

.signupFormPasswordAttention {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-align: center;
  margin-top: -30px;
  margin-bottom: 30px;
}

.signupFormProcessing {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 820px) {
  .signupForm {
    padding: 80px 20px 80px 20px;
  }
}

/*============================
会員登録完了ページ
============================*/
.signupSuccessPage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.signupSuccessPage h1 {
  font-size: 32px;
  font-weight: bold;
  padding: 80px;
}

.signupSuccessPage p {
  margin-bottom: 50px;
}

.signupSuccessPage a {
  color: black;
  background-color: black;
  color: white;
  padding: 20px 80px 20px 80px;
  text-decoration: none;
}

.signupSuccessPage a:hover {
  opacity: 0.3;
}

@media (max-width: 820px) {
  .signupSuccessPage h1 {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.1em;
    padding: 50px;
  }
}

/*============================
ログインページ
============================*/
.loginForm {
  padding: 80px;
}

.loginError {
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  font-weight: bold;
}

.loginFormItem {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.loginFormItem p {
  font-weight: bold;
  text-align: left;
  padding-bottom: 10px;
  font-size: 16px;
}

.loginFormItem input[type="mail"],
.loginFormItem input[type="password"] {
  padding: 10px;
  width: 250px;
  font-size: 16px;
  outline: none;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.loginFormItem input:focus {
  outline: none;
  border: 1.5px solid #ff3f33;
  border-radius: 5px;
}

.loginFormItem button {
  text-decoration: none;
  color: white;
  padding: 15px;
  background-color: #ff3f33;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 50px;
  width: 150px;
  transition: 0.3s;
}

.loginFormItem button:hover {
  opacity: 0.3;
}

.loginFormSignup,
.loginFormPasswordReset {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.loginFormSignup a,
.loginFormPasswordReset a {
  border-radius: 5px;
  transition: 0.3s;
  color: black;
  font-size: 16px;
}

.loginFormSignup a:hover,
.loginFormPasswordReset a:hover {
  opacity: 0.3;
}

/*============================
パスワードリセットページ
============================*/
.passwordResetPageTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.passwordResetPageTitle h1 {
  font-size: 32px;
  font-weight: bold;
  padding: 80px;
}

.passwordResetFormItem {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.passwordResetFormItem p {
  font-weight: bold;
  text-align: left;
  padding-bottom: 10px;
  font-size: 16px;
}

.passwordResetFormItem input {
  padding: 10px;
  width: 250px;
  font-size: 16px;
  outline: none;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.passwordResetAttention {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  padding: 0 50px;
  font-size: 12px;
}

.passwordResetFormItem button {
  text-decoration: none;
  color: white;
  padding: 15px;
  background-color: #ff3f33;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 50px;
  width: 150px;
  transition: 0.3s;
}

.passwordResetFormItem button:hover {
  opacity: 0.3;
}

.passwordResetFormItem button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.passwordResetError {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  margin-bottom: 30px;
}

.passwordResetFormProcessing {
  display: flex;
  justify-content: center;
  align-items: center;
}

.passwordResetComplete p {
  font-size: 16px;
  padding: 20px;
}

.passwordResetComplete a {
  color: #111111;
}

@media (max-width: 820px) {
  .passwordResetPageTitle h1 {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.1em;
    padding: 50px;
  }
}

/*============================
パスワード変更ページ
============================*/
.passwordSettingPageTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.passwordSettingPageTitle h1 {
  font-size: 32px;
  font-weight: bold;
  padding: 80px;
}

.passwordSettingFormItem {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.passwordSettingFormItem p {
  font-weight: bold;
  text-align: left;
  padding-bottom: 10px;
  font-size: 16px;
}

.passwordSettingFormItem input[type="text"],
.passwordSettingFormItem input[type="password"] {
  padding: 10px;
  width: 250px;
  font-size: 16px;
  outline: none;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.passwordSettingFormItem input:focus {
  outline: none;
  border: 1.5px solid #ff3f33;
  border-radius: 5px;
}

.passwordSettingFormItem button {
  text-decoration: none;
  color: white;
  padding: 15px;
  background-color: #ff3f33;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 50px;
  width: 150px;
  transition: 0.3s;
}

.passwordSettingFormItem button:hover {
  opacity: 0.3;
}

.passwordSettingFormItem button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.passwordSettingFormAttention {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-align: center;
  margin-top: -30px;
  margin-bottom: 30px;
  padding: 0 50px;
}

.passwordSettingError {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  margin-bottom: 30px;
}

@media (max-width: 820px) {
  .passwordSettingPageTitle h1 {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.1em;
    padding: 50px;
  }
}

/*============================
パスワード変更完了ページ
============================*/
.passwordSettingSuccessPagePage {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.passwordSettingSuccessPage h1 {
  font-size: 32px;
  font-weight: bold;
  padding: 80px;
}

.passwordSettingSuccessPage p {
  margin-bottom: 50px;
}

.passwordSettingSuccessPage a {
  color: white;
  background-color: #ff3f33;
  color: white;
  padding: 20px 80px 20px 80px;
  text-decoration: none;
}

.passwordSettingSuccessPage a:hover {
  opacity: 0.3;
}

@media (max-width: 820px) {
  .passwordSettingSuccessPage h1 {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.1em;
    padding: 50px;
  }
}

/*============================
会員情報変更ページ
============================*/
.profileSettingPageTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileSettingPageTitle h1 {
  font-size: 32px;
  font-weight: bold;
  padding: 80px;
}

.profileSettingFormItem {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.profileSettingFormItem p {
  font-weight: bold;
  text-align: left;
  padding-bottom: 10px;
  font-size: 16px;
}

.signupFormRequiredText {
  background-color: red;
  color: white;
  padding: 5px 5px 5px 5px;
  border-radius: 5px;
  margin-left: 20px;
}

.profileSettingFormItem input[type="text"],
.signupFormItem input[type="mail"],
.signupFormItem input[type="password"] {
  padding: 10px;
  width: 250px;
  font-size: 16px;
  outline: none;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.profileSettingFormItem input[type="checkbox"] {
  margin: 10px;
}

.profileSettingFormItem input:focus {
  outline: none;
  border: 1.5px solid #ff3f33;
  border-radius: 5px;
}

.profileSettingFormItem button {
  text-decoration: none;
  color: white;
  padding: 15px;
  background-color: #ff3f33;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 50px;
  width: 150px;
  transition: 0.3s;
}

.profileSettingFormItem button:hover {
  opacity: 0.3;
}

.profileSettingFormItem button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

@media (max-width: 820px) {
  .profileSettingPageTitle h1 {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.1em;
    padding: 50px;
  }
}

/*============================
会員情報変更完了ページ
============================*/
.profileSettingSuccessPage {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.profileSettingSuccessPage h1 {
  font-size: 32px;
  font-weight: bold;
  padding: 80px;
}

.profileSettingSuccessPage p {
  margin-bottom: 50px;
}

.profileSettingSuccessPage a {
  color: white;
  background-color: #ff3f33;
  color: white;
  padding: 20px 80px 20px 80px;
  text-decoration: none;
}

.profileSettingSuccessPage a:hover {
  opacity: 0.3;
}

@media (max-width: 820px) {
  .profileSettingSuccessPage h1 {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.1em;
    padding: 50px;
  }
}

/*============================
募集一覧ページ
============================*/
#PROJECT {
  padding-bottom: 200px;
  backdrop-filter: blur(8px);
}

#PROJECT .inner {
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
}

#PROJECT .inner .TabList {
  padding: 80px 0;
}

#PROJECT .inner .TabList ul {
  display: flex;
  justify-content: space-between;
}

#PROJECT .inner .TabList ul li {
  width: 22.6%;
  margin: 10px 0;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 62px;
}

#PROJECT .inner .TabList ul li.all {
  border: 1px solid;
  background-color: #ffffff;
  color: #ff3f33;
}

#PROJECT .inner .TabList ul li.all:hover {
  border: 1px solid;
  border: 1px solid #ff3f33;
  background-color: #ff3f33;
  color: #ffffff;
  transition: 0.3s;
  cursor: pointer;
}

#PROJECT .inner .TabList ul li.solution {
  border: 1px solid;
  background-color: #ffffff;
  color: #1a9d4e;
  position: relative;
}

#PROJECT .inner .TabList ul li.solution:hover {
  border: 1px solid;
  border: 1px solid #1a9d4e;
  background-color: #1a9d4e;
  color: #ffffff;
  transition: 0.3s;
  cursor: pointer;
}

#PROJECT .inner .TabList ul li.dx {
  border: 1px solid;
  background-color: #ffffff;
  color: #fc0e92;
  position: relative;
}

#PROJECT .inner .TabList ul li.dx:hover {
  border: 1px solid;
  border: 1px solid #fc0e92;
  background-color: #fc0e92;
  color: #ffffff;
  transition: 0.3s;
  cursor: pointer;
}

#PROJECT .inner .TabList ul li.free {
  border: 1px solid;
  background-color: #ffffff;
  color: #14a9e1;
  position: relative;
}

#PROJECT .inner .TabList ul li.free:hover {
  border: 1px solid;
  border: 1px solid #14a9e1;
  background-color: #14a9e1;
  color: #ffffff;
  transition: 0.3s;
  cursor: pointer;
}

#PROJECT .inner .TabList ul li svg {
  height: 24px;
  margin-right: 8px;
  vertical-align: sub;
}
#PROJECT .inner .TabList ul li.solution svg {
  fill: #1a9d4e;
}
#PROJECT .inner .TabList ul li.dx svg {
  fill: #fc0e92;
}
#PROJECT .inner .TabList ul li.free svg {
  fill: #14a9e1;
}
#PROJECT .inner .TabList ul li:hover svg {
  fill: #ffffff;
}

#PROJECT .inner .ArticleList {
  width: auto;
}

#PROJECT .inner .ArticleList article {
  margin-top: 80px;
}

#PROJECT .inner .ArticleList article:first-of-type {
  margin-top: 0;
}

#PROJECT .inner .ArticleList article a.article-link {
  display: block;
  padding: 40px;
  background-color: #ffffff;
  text-decoration: none;
}

#PROJECT .inner .ArticleList article.solution a.article-link:hover {
  background-color: #1a9d4e;
}
#PROJECT .inner .ArticleList article.dx a.article-link:hover {
  background-color: #fc0e92;
}
#PROJECT .inner .ArticleList article.free a.article-link:hover {
  background-color: #14a9e1;
}

/*過去のプロジェクトのリンク切るために追加したコード*/
#PROJECT .inner .ArticleList article.disabled a.article-link {
  cursor: not-allowed;
  background-color: #c1c7c6;
  opacity: 0.7;
}

#PROJECT .inner .ArticleList article.disabled a.article-link:hover {
  cursor: not-allowed;
  background-color: #c1c7c6;
  opacity: 0.7;
}

#PROJECT
  .inner
  .ArticleList
  article.disabled
  a.article-link
  .articleblock
  .detail
  .link
  p.article-buttom {
  color: white;
  fill: white;
}

#PROJECT
  .inner
  .ArticleList
  article.disabled
  a.article-link
  .articleblock
  .detail
  .link
  p.article-buttom
  svg {
  color: white;
  fill: white;
}
/*過去のプロジェクトのリンク切るために追加したコード*/

#PROJECT .inner .ArticleList article a.article-link h2.title {
  padding-bottom: 20px;
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 1px solid #dbdbdb;
  color: #111111;
}

#PROJECT .inner .ArticleList article a.article-link .articleblock {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

#PROJECT .inner .ArticleList article a.article-link .articleblock .logo {
  width: 37.7%;
}

#PROJECT .inner .ArticleList article a.article-link .articleblock .logo img {
  height: 100%;
  max-height: 150px;
  object-fit: contain;
}

#PROJECT .inner .ArticleList article a.article-link .articleblock .detail {
  width: 58.4%;
  text-align: left;
}

#PROJECT
  .inner
  .ArticleList
  article
  a.article-link
  .articleblock
  .detail
  p.text {
  font-size: 14px;
  letter-spacing: 0.05em;
}

#PROJECT
  .inner
  .ArticleList
  article
  a.article-link
  .articleblock
  .detail
  ul.tag {
  padding: 20px 0;
}

#PROJECT
  .inner
  .ArticleList
  article
  a.article-link
  .articleblock
  .detail
  ul.tag
  li {
  display: inline-block;
  margin: 2px;
  padding: 8px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.1em;
  border: 1px solid;
  background-color: #ffffff;
  position: relative;
}

#PROJECT
  .inner
  .ArticleList
  article
  a.article-link
  .articleblock
  .detail
  ul.tag
  li
  svg {
  height: 16px;
  margin-right: 8px;
  vertical-align: sub;
}

#PROJECT
  .inner
  .ArticleList
  article.solution
  a.article-link
  .articleblock
  .detail
  ul.tag
  li.tag-thema {
  color: #1a9d4e;
}
#PROJECT
  .inner
  .ArticleList
  article.solution
  a.article-link
  .articleblock
  .detail
  ul.tag
  li.tag-thema
  svg {
  fill: #1a9d4e;
}
#PROJECT
  .inner
  .ArticleList
  article.dx
  a.article-link
  .articleblock
  .detail
  ul.tag
  li.tag-thema {
  color: #fc0e92;
}
#PROJECT
  .inner
  .ArticleList
  article.dx
  a.article-link
  .articleblock
  .detail
  ul.tag
  li.tag-thema
  svg {
  fill: #fc0e92;
}
#PROJECT
  .inner
  .ArticleList
  article.free
  a.article-link
  .articleblock
  .detail
  ul.tag
  li.tag-thema {
  color: #14a9e1;
}
#PROJECT
  .inner
  .ArticleList
  article.free
  a.article-link
  .articleblock
  .detail
  ul.tag
  li.tag-thema
  svg {
  fill: #14a9e1;
}

#PROJECT
  .inner
  .ArticleList
  article
  a.article-link
  .articleblock
  .detail
  ul.tag
  li.tag-intention {
  color: #959595;
}

#PROJECT
  .inner
  .ArticleList
  article
  a.article-link
  .articleblock
  .detail
  ul.tag
  li.tag-intention
  svg {
  height: 16px;
  vertical-align: sub;
  fill: #959595;
}

#PROJECT
  .inner
  .ArticleList
  article
  a.article-link
  .articleblock
  .detail
  .link {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
}

#PROJECT
  .inner
  .ArticleList
  article
  a.article-link
  .articleblock
  .detail
  .link
  p.date {
  margin-right: 20px;
  font-size: 14px;
  letter-spacing: 0.1em;
  color: #959595;
}

#PROJECT
  .inner
  .ArticleList
  article
  a.article-link
  .articleblock
  .detail
  .link
  p.date.dead {
  padding: 2px 8px;
  background-color: #959595;
  color: #ffffff;
}

#PROJECT
  .inner
  .ArticleList
  article
  a.article-link:hover
  .articleblock
  .detail
  .link
  p.date {
  color: #ffffff;
}

#PROJECT
  .inner
  .ArticleList
  article.disabled
  a.article-link
  .articleblock
  .detail
  .link
  p.date {
  color: #ffffff;
}

#PROJECT
  .inner
  .ArticleList
  article
  a.article-link
  .articleblock
  .detail
  .link
  p.article-buttom {
  font-size: 14px;
  letter-spacing: 0.1em;
}

#PROJECT
  .inner
  .ArticleList
  article
  a.article-link
  .articleblock
  .detail
  .link {
  display: block;
  text-align: right;
}

#PROJECT
  .inner
  .ArticleList
  article
  a.article-link
  .articleblock
  .detail
  .link
  p.date {
  margin-right: 0;
}

#PROJECT
  .inner
  .ArticleList
  article
  a.article-link
  .articleblock
  .detail
  .link
  p.article-buttom {
  padding-top: 8px;
}

/* SP */
@media (max-width: 820px) {
  #PROJECT .inner .TabList ul {
    display: block;
  }

  #PROJECT .inner .TabList ul li {
    width: auto;
    padding-left: 20px;
    text-align: left;
  }

  #PROJECT .inner .ArticleList article a.article-link {
    padding: 24px;
  }

  #PROJECT .inner .ArticleList article a.article-link h2.title {
    font-size: 18px;
  }

  #PROJECT .inner .ArticleList article a.article-link .articleblock {
    display: block;
  }

  #PROJECT .inner .ArticleList article a.article-link .articleblock .logo {
    width: auto;
    margin: 20px 0;
  }

  #PROJECT .inner .ArticleList article a.article-link .articleblock .detail {
    width: auto;
  }

  #PROJECT
    .inner
    .ArticleList
    article
    a.article-link
    .articleblock
    .detail
    .link {
    justify-content: space-between;
  }
}

/*============================
募集詳細ページ
============================*/
/*PageTitle*/
#DETAIL .PageTitle {
  text-align: left;
  padding-bottom: 142px;
}
#DETAIL .PageTitle h1.detail-title {
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: 0.1em;
}
#DETAIL .PageTitle p.sub-title {
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
  padding-top: 20px;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.1em;
}

/*keyimage*/
#DETAIL .keyimage {
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
  margin-bottom: -62px;

  position: relative;
  top: -62px;
}

#DETAIL .keyimage img {
  aspect-ratio: 16 / 9;
  height: 100%;
  object-fit: cover;
}

/*DetailContainer*/
#DETAIL .DetailContainer {
  width: auto;
}

#DETAIL .DetailContainer p.text {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.05em;
}

/*contentblock*/
#DETAIL .DetailContainer .contentblock {
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
  padding-top: 160px;
  text-align: left;
}

#DETAIL .DetailContainer .contentblock:first-of-type {
  padding-top: 80px;
}

#DETAIL .DetailContainer .contentblock p.text {
  padding-top: 80px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.05em;
}

#DETAIL .DetailContainer .contentblock p.read-text {
  padding-top: 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.05em;
}

#DETAIL .DetailContainer .contentblock h2.common-title {
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.1em;
  border-bottom: 1px solid #dbdbdb;
}

#DETAIL .DetailContainer .contentblock .bginner h2.bg-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

#DETAIL .DetailContainer .contentblock p.text {
  padding-top: 40px;
}

#DETAIL .DetailContainer .contentblock figure.common-image {
  width: 65.62%;
  margin: 0 auto;
  padding-top: 80px;
}

#DETAIL .DetailContainer .contentblock figure.common-image img {
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

/*read*/
#DETAIL .DetailContainer .contentblock h3.read-title {
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

#DETAIL .DetailContainer .contentblock p.position {
  padding-top: 40px;
  text-align: right;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.1em;
  color: #959595;
}

#DETAIL .DetailContainer .contentblock p.name {
  text-align: right;
  padding-top: 16px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

#DETAIL .DetailContainer .contentblock .logoblock {
  width: 48.43%;
  margin: 0 auto;
  padding: 80px 0;
}

/*infolist*/
#DETAIL .DetailContainer .contentblock .infolist {
  border-top: 2px solid #111111;
  border-bottom: 2px solid #111111;
}

#DETAIL .DetailContainer .contentblock .infolist dl {
  border-left: 2px solid #111111;
  border-right: 2px solid #111111;
  display: flex;
  justify-content: space-between;
}

#DETAIL .DetailContainer .contentblock .infolist dl dt {
  width: 48.43%;
  border-right: 1px solid #111111;
  background-color: #dbdbdb;
}

#DETAIL .DetailContainer .contentblock .infolist dl dd {
  width: 100%;
}

#DETAIL .DetailContainer .contentblock .infolist dl p {
  margin: 0 40px;
  padding: 20px 0;
  font-size: 16px;
  letter-spacing: 0.05em;
}

#DETAIL .DetailContainer .contentblock .infolist dt p {
  font-weight: bold;
}

#DETAIL .DetailContainer .contentblock .infolist a {
  text-decoration: none;
}

/*contentblock thema*/
#DETAIL .DetailContainer .contentblock.bg {
  width: 100%;
  max-width: none;
  margin: 0 auto;
  margin-top: 160px;
  padding: 0;
  background-color: #fff7f4;
}

#DETAIL .DetailContainer .contentblock .bginner {
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 80px 0;
}

#DETAIL .DetailContainer .contentblock.bg .bginner .thema-list {
  padding-top: 80px;
}

#DETAIL .DetailContainer .contentblock.bg .bginner .thema-list .listblock {
  padding-top: 40px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

#DETAIL
  .DetailContainer
  .contentblock.bg
  .bginner
  .thema-list
  .listblock:first-of-type {
  padding-top: 0;
}
#DETAIL
  .DetailContainer
  .contentblock.bg
  .bginner
  .thema-list
  .listblock.on
  .icon {
  width: 22.6%;
  border-radius: 8px;
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
#DETAIL
  .DetailContainer
  .contentblock.bg
  .bginner
  .thema-list
  .listblock.on.solution
  .icon {
  background-color: #1a9d4e;
}
#DETAIL
  .DetailContainer
  .contentblock.bg
  .bginner
  .thema-list
  .listblock.on.dx
  .icon {
  background-color: #fc0e92;
}
#DETAIL
  .DetailContainer
  .contentblock.bg
  .bginner
  .thema-list
  .listblock.on.free
  .icon {
  background-color: #14a9e1;
}

#DETAIL
  .DetailContainer
  .contentblock.bg
  .bginner
  .thema-list
  .listblock.on
  .icon
  .inner {
  text-align: center;
}

#DETAIL
  .DetailContainer
  .contentblock.bg
  .bginner
  .thema-list
  .listblock.on
  .icon
  .inner
  svg {
  fill: #ffffff;
}

#DETAIL
  .DetailContainer
  .contentblock.bg
  .bginner
  .thema-list
  .listblock.on
  .icon
  .inner
  p {
  padding-top: 8px;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.1em;
  color: #ffffff;
}

#DETAIL
  .DetailContainer
  .contentblock.bg
  .bginner
  .thema-list
  .listblock.off
  .icon {
  width: 22.6%;
  border-radius: 4px;
  border: 2px solid #959595;
  background-color: #ffffff;
}

#DETAIL
  .DetailContainer
  .contentblock.bg
  .bginner
  .thema-list
  .listblock.off
  .icon
  .inner {
  padding: 8px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#DETAIL
  .DetailContainer
  .contentblock.bg
  .bginner
  .thema-list
  .listblock.off
  .icon
  .inner
  svg {
  fill: #959595;
  margin-right: 12px;
}

#DETAIL
  .DetailContainer
  .contentblock.bg
  .bginner
  .thema-list
  .listblock.off
  .icon
  .inner
  p {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.1em;
  color: #959595;
}

#DETAIL
  .DetailContainer
  .contentblock.bg
  .bginner
  .thema-list
  .listblock
  p.title {
  width: 74.21%;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.1em;
  color: #959595;
}

/*contentblock intention*/
#DETAIL .DetailContainer .contentblock.bg .bginner .icon-list {
  padding-top: 80px;

  display: flex;
  justify-content: space-around;
}

#DETAIL .DetailContainer .contentblock.bg .bginner .icon-list .icon-block {
  padding: 20px;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.1em;
  border-radius: 4px;
  border: 2px solid #111111;
  background-color: #dbdbdb;
}

/* intention-list */
#DETAIL .DetailContainer .contentblock.bg .bginner .intention-list {
  padding-top: 80px;
}

#DETAIL .DetailContainer .contentblock.bg .bginner .intention-list .listblock {
  padding-top: 40px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

#DETAIL
  .DetailContainer
  .contentblock.bg
  .bginner
  .intention-list
  .listblock:first-of-type {
  padding-top: 0;
}

#DETAIL
  .DetailContainer
  .contentblock.bg
  .bginner
  .intention-list
  .listblock
  .icon {
  width: 22.6%;
  border-radius: 4px;
  background-color: #ffffff;
}

#DETAIL
  .DetailContainer
  .contentblock.bg
  .bginner
  .intention-list
  .listblock.off
  .icon {
  border: 2px solid #959595;
}

#DETAIL
  .DetailContainer
  .contentblock.bg
  .bginner
  .intention-list
  .listblock
  .icon
  .inner {
  padding: 8px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#DETAIL
  .DetailContainer
  .contentblock.bg
  .bginner
  .intention-list
  .listblock
  .icon
  .inner
  svg {
  fill: #959595;
  margin-right: 12px;
}

#DETAIL
  .DetailContainer
  .contentblock.bg
  .bginner
  .intention-list
  .listblock
  .icon
  .inner
  p {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.1em;
  color: #959595;
}

#DETAIL
  .DetailContainer
  .contentblock.bg
  .bginner
  .intention-list
  .listblock
  p.title {
  width: 74.21%;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.1em;
  color: #959595;
}

#DETAIL
  .DetailContainer
  .contentblock.bg
  .bginner
  .intention-list
  .listblock.on
  .icon {
  border: 2px solid #111111;
}

#DETAIL
  .DetailContainer
  .contentblock.bg
  .bginner
  .intention-list
  .listblock.on
  .icon
  .inner
  p {
  color: #111111;
}

#DETAIL
  .DetailContainer
  .contentblock.bg
  .bginner
  .intention-list
  .listblock.on
  p.title {
  color: #111111;
}

/*common-list*/
#DETAIL .DetailContainer .contentblock ul.common-list {
  padding-top: 80px;
}

#DETAIL .DetailContainer .contentblock ul.common-list li {
  padding-top: 40px;
}

#DETAIL .DetailContainer .contentblock ul.common-list li:first-child {
  padding-top: 0;
}

#DETAIL .DetailContainer .contentblock ul.common-list li dl {
  width: auto;
}

#DETAIL .DetailContainer .contentblock ul.common-list li dl dt {
  width: auto;
}

#DETAIL .DetailContainer .contentblock ul.common-list li dl dt p.title {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

#DETAIL .DetailContainer .contentblock ul.common-list li dl dt p.title img {
  width: 40px;
  margin-right: 8px;
}

#DETAIL .DetailContainer .contentblock ul.common-list li dl dd {
  width: auto;
}

#DETAIL .DetailContainer .contentblock ul.common-list li dl dd p.text {
  padding-top: 16px;
}

/*attention-list*/
#DETAIL .DetailContainer .contentblock ul.attention-list {
  padding-top: 80px;
}

#DETAIL .DetailContainer .contentblock ul.attention-list li {
  padding-top: 40px;
}

#DETAIL .DetailContainer .contentblock ul.attention-list li:first-child {
  padding-top: 0;
}

#DETAIL .DetailContainer .contentblock ul.attention-list li dl {
  width: auto;
  border-radius: 8px;
  border: 2px solid #111111;
  overflow: hidden;
}

#DETAIL .DetailContainer .contentblock ul.attention-list li dl dt {
  width: auto;
  padding: 20px;
  border-bottom: 2px solid #111111;
  background-color: #dbdbdb;
}

#DETAIL .DetailContainer .contentblock ul.attention-list li dl dt p.title {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

#DETAIL .DetailContainer .contentblock ul.attention-list li dl dt p.title img {
  width: 40px;
  margin-right: 8px;
}

#DETAIL .DetailContainer .contentblock ul.attention-list li dl dd {
  padding: 20px;
}

#DETAIL .DetailContainer .contentblock ul.attention-list li dl dd p.text {
  padding-top: 16px;
}

/*text-box*/
#DETAIL .DetailContainer .contentblock .text-box {
  margin-top: 80px;
  border: 4px solid #ff3f33;
}

#DETAIL .DetailContainer .contentblock .text-box h4.read-text {
  padding: 80px;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

#DETAIL .DetailContainer .contentblock .text-box h4.read-text {
  padding: 80px;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

#DETAIL .DetailContainer .contentblock .text-box .read-text a {
  color: black;
  text-decoration: none;
}

#DETAIL .DetailContainer .contentblock .text-box p.text {
  padding: 80px;
  padding-top: 0;
  color: #959595;
  text-align: center;
}

#DETAIL .DetailContainer .contentblock .text-box a {
  color: #959595;
}

/*schedule-list*/
#DETAIL .DetailContainer .contentblock ul.schedule-list {
  padding-top: 80px;
}

#DETAIL .DetailContainer .contentblock ul.schedule-list li {
  padding-top: 40px;
}

#DETAIL .DetailContainer .contentblock ul.schedule-list li:first-child {
  padding-top: 0;
}

#DETAIL .DetailContainer .contentblock ul.schedule-list li dl {
  padding: 40px;
  background-color: #dbdbdb;
  border-radius: 8px;
}

#DETAIL .DetailContainer .contentblock ul.schedule-list li dl dt {
  width: auto;
}

#DETAIL .DetailContainer .contentblock ul.schedule-list li dl dt p.title {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

#DETAIL .DetailContainer .contentblock ul.schedule-list li dl dt p.title img {
  width: 40px;
  margin-right: 8px;
}

#DETAIL .DetailContainer .contentblock ul.schedule-list li dl dd {
  width: auto;
}

#DETAIL .DetailContainer .contentblock ul.schedule-list li dl dd p.text {
  padding-top: 16px;
}

#DETAIL .DetailContainer .contentblock ul.schedule-list li {
  padding-bottom: 66px;
  position: relative;
}

#DETAIL .DetailContainer .contentblock ul.schedule-list li::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 22px solid #fdb717;
  position: absolute;
  right: 46%;
  bottom: 0;
  transform: translate(-50%, -50%);
}

#DETAIL .DetailContainer .contentblock ul.schedule-list li:last-child {
  padding-bottom: 0;
}

#DETAIL .DetailContainer .contentblock ul.schedule-list li:last-child:before {
  content: none;
}

#DETAIL
  .DetailContainer
  .contentblock.bg
  .bginner
  .thema-list
  .listblock.on
  p.title {
  color: #111111;
}

#DETAIL .linkToEntry {
  text-decoration: none;
}

#DETAIL .text-box a:hover {
  opacity: 0.5;
}

/* SP */
@media (max-width: 820px) {
  #DETAIL .PageTitle {
    padding-bottom: 100px;
  }
  #DETAIL .PageTitle h1.detail-title {
    font-size: 32px;
  }
  #DETAIL .PageTitle p.sub-title {
    font-size: 18px;
  }

  #DETAIL .keyimage {
    margin-bottom: -42px;
    top: -42px;
  }

  #DETAIL .DetailContainer .contentblock {
    padding-top: 120px;
  }

  #DETAIL .DetailContainer .contentblock .logoblock {
    width: 80%;
    margin: 0 auto;
    padding: 80px 0;
  }
  #DETAIL .DetailContainer .contentblock .infolist dl {
    display: block;
  }
  #DETAIL .DetailContainer .contentblock .infolist dl dt {
    width: auto;
    border: none;
  }

  #DETAIL .DetailContainer .contentblock.bg .bginner .thema-list .listblock {
    display: block;
  }
  #DETAIL
    .DetailContainer
    .contentblock.bg
    .bginner
    .thema-list
    .listblock.on
    .icon {
    width: 59.6%;
    margin: 0 auto;
  }
  #DETAIL
    .DetailContainer
    .contentblock.bg
    .bginner
    .thema-list
    .listblock.off
    .icon {
    width: 59.6%;
    margin: 0 auto;
  }
  #DETAIL
    .DetailContainer
    .contentblock.bg
    .bginner
    .thema-list
    .listblock.on
    .icon
    .inner
    p {
    font-size: 18px;
  }
  #DETAIL
    .DetailContainer
    .contentblock.bg
    .bginner
    .thema-list
    .listblock
    p.title {
    width: auto;
    padding-top: 20px;
    text-align: center;
    font-size: 16px;
  }
  #DETAIL .DetailContainer .contentblock figure.common-image {
    width: 85.62%;
    margin: 0 auto;
    padding-top: 80px;
  }

  #DETAIL .DetailContainer .contentblock.bg .bginner .icon-list {
    display: block;
  }

  #DETAIL .DetailContainer .contentblock.bg .bginner .icon-list .icon-block {
    width: 85.62%;
    margin: 16px auto;
    text-align: center;
    padding: 16px;
    font-size: 24px;
  }

  #DETAIL
    .DetailContainer
    .contentblock.bg
    .bginner
    .intention-list
    .listblock {
    display: block;
  }
  #DETAIL
    .DetailContainer
    .contentblock.bg
    .bginner
    .intention-list
    .listblock
    .icon {
    width: auto;
  }
  #DETAIL
    .DetailContainer
    .contentblock.bg
    .bginner
    .intention-list
    .listblock
    p.title {
    width: auto;
    padding-top: 20px;
    text-align: center;
    font-size: 16px;
  }
  #DETAIL .DetailContainer .contentblock .text-box {
    border: 4px solid #ff3f33;
  }
  #DETAIL .DetailContainer .contentblock .text-box h4.read-text {
    padding: 40px;
    font-size: 24px;
  }
  #DETAIL .DetailContainer .contentblock .text-box p.text {
    padding: 40px;
    padding-top: 0;
  }

  #DETAIL .DetailContainer .contentblock ul.schedule-list li::before {
    right: 40%;
  }
}

/*============================
イベントページ
============================*/
#EVENT {
  padding-bottom: 200px;
  backdrop-filter: blur(8px);
}

#EVENT .inner {
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
  padding-top: 160px;
}

#EVENT .inner .disabled {
  opacity: 0.4 !important;
  background-color: #959595 !important;
}

#EVENT .inner .ArticleList {
  width: auto;
}

#EVENT .inner .ArticleList .ArticleListText {
  padding-bottom: 50px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-align: center;
}

#EVENT .inner .ArticleList article {
  margin-top: 80px;
  padding: 80px;
  padding-bottom: 160px;
  text-align: left;
  background-color: #ffffff;

  position: relative;
}

#EVENT .inner .ArticleList article:first-of-type {
  margin-top: 0;
}

#EVENT .inner .ArticleList article h2.title {
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

#EVENT .inner .ArticleList article .imageblock {
  padding: 40px 0;
}

#EVENT .inner .ArticleList article p.text {
  font-size: 16px;
  letter-spacing: 0.05em;
}

#EVENT .inner .ArticleList article .detail-list {
  padding-top: 40px;
}

#EVENT .inner .ArticleList article .detail-list dl.detail {
  padding-top: 28px;
  display: flex;
}

#EVENT .inner .ArticleList article .detail-list dl.detail dt {
  width: 48.43%;
}

#EVENT .inner .ArticleList article .detail-list dl.detail dt p {
  padding-left: 20px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.05em;

  position: relative;
}
#EVENT .inner .ArticleList article .detail-list dl.detail dt p:before {
  content: "";
  width: 10px;
  height: 10px;
  text-align: center;
  border-radius: 50%;
  line-height: 10px;
  background: #1a9d4e;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
#EVENT
  .inner
  .ArticleList
  article
  .detail-list
  dl.detail:nth-of-type(2)
  dt
  p:before {
  background: #14a9e1;
}
#EVENT
  .inner
  .ArticleList
  article
  .detail-list
  dl.detail:nth-of-type(3)
  dt
  p:before {
  background: #fc0e92;
}
#EVENT
  .inner
  .ArticleList
  article
  .detail-list
  dl.detail:nth-of-type(4)
  dt
  p:before {
  background: #fdb717;
}
#EVENT
  .inner
  .ArticleList
  article
  .detail-list
  dl.detail:nth-of-type(5)
  dt
  p:before {
  background: #7171e5;
}
#EVENT
  .inner
  .ArticleList
  article
  .detail-list
  dl.detail:nth-of-type(6)
  dt
  p:before {
  background: #ff6712;
}

#EVENT .inner .ArticleList article .detail-list dl.detail dd {
  width: 100%;
  font-size: 16px;
  letter-spacing: 0.05em;
}

#EVENT .inner .ArticleList article .btn-block {
  position: absolute;
  bottom: 0;
  right: 0;
}

#EVENT .inner .ArticleList article .btn-block a {
  display: block;
  min-width: 200px;
  padding: 0 40px 0 20px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 80px;
  background-color: #ff3f33;
  color: #ffffff;
  text-decoration: none;
  position: relative;
  text-align: center;
}

#EVENT .inner .ArticleList article .btn-block .btn-end {
  display: block;
  min-width: 200px;
  padding: 0 40px 0 20px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 80px;
  background-color: black;
  color: #ffffff;
  text-decoration: none;
  position: relative;
  text-align: center;
}
#EVENT .inner .ArticleList article .btn-block a:hover {
  background-color: #111111;
}

#EVENT .inner .ArticleList article .btn-block .btn-blockText {
  font-size: 14px;
  color: #959595;
  padding-bottom: 10px;
}

#EVENT .inner .ArticleList article .btn-block a svg {
  fill: #ffffff;
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* SP */
@media (max-width: 820px) {
  #EVENT .inner .ArticleList article {
    padding: 40px;
    padding-bottom: 160px;
  }
  #EVENT .inner .ArticleList article h2.title {
    font-size: 32px;
  }

  #EVENT .inner .ArticleList article .detail-list dl.detail {
    display: block;
    padding: 20px 0;
    border-bottom: 1px solid #dbdbdb;
  }
  #EVENT .inner .ArticleList article .detail-list dl.detail:last-of-type {
    padding: 20px 0 0 0;
    border-bottom: none;
  }

  #EVENT .inner .ArticleList article .detail-list dl.detail dt {
    width: auto;
  }

  #EVENT .inner .ArticleList article .detail-list dl.detail dd {
    padding-top: 20px;
  }

  #EVENT .inner .ArticleList article .btn-block {
    left: 0;
  }

  #EVENT .inner .ArticleList article .btn-block a {
    width: 100%;
    padding: 0;
    text-align: center;
  }
  #EVENT .inner .ArticleList article .btn-block .btn-blockText {
    text-align: center;
  }
}

/*============================
CONTACT
============================*/

#CONTACT .ContentsContainer {
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
}

#CONTACT .ContentsContainer p.read-text {
  padding-top: 80px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.05em;
}

#CONTACT .ContentsContainer h2.title {
  padding-top: 80px;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

/*============================
form
============================*/

#CONTACT .Form {
  padding-top: 80px;
  text-align: left;
}
#CONTACT .Form-Item {
  width: 100%;
  max-width: 65.62%;
  margin: 0 auto;
  padding: 20px 0;

  display: flex;
}
#CONTACT .Form-Item-Label {
  width: 100%;
  max-width: 248px;
  letter-spacing: 0.05em;
  font-weight: bold;
  font-size: 16px;
}
#CONTACT .Form-Item-Label.isMsg {
  margin-top: 8px;
  margin-bottom: auto;
}
#CONTACT .Form-Item-Input {
  border: 1px solid #111111;
  border-radius: 6px;
  margin-left: 40px;
  padding-left: 1em;
  padding-right: 1em;
  padding: 0 1em;
  height: 48px;
  flex: 1;
  width: 100%;
  max-width: 410px;
  background: #fafafa;
  font-size: 16px;
  letter-spacing: 0.05em;
  cursor: pointer;
}

#CONTACT input {
  box-sizing: border-box;
  cursor: pointer;
}

#CONTACT textarea {
  box-sizing: border-box;
  cursor: pointer;
}

#CONTACT .Form-Item-Radio {
  max-width: 410px;
  border-radius: 6px;
  margin-left: 40px;
  padding: 0 1em;
  height: 48px;
  flex: 1;
  accent-color: #111111;
  /* 大きさ */
  inline-size: 1rem;
  block-size: 1rem;

  background: #fafafa;
  font-size: 16px;
  letter-spacing: 0.05em;
}
#CONTACT label span.radio-text {
  margin-left: 4px;
  font-size: 14px;
}
#CONTACT ul.radio-list li {
  padding: 10px 0;
}
#CONTACT .Form-Item .Form-Item-Textarea {
  border: 1px solid #111111;
  border-radius: 6px;
  margin-left: 40px;
  padding-left: 1em;
  padding-right: 1em;
  padding: 1em;
  height: 216px;
  flex: 1;
  width: 100%;
  max-width: 410px;
  background: #fafafa;
  font-size: 16px;
  letter-spacing: 0.05em;
}

#CONTACT .Form p.kome {
  color: #959595;
}

#CONTACT .form-conform {
  text-align: center;
  margin-top: 40px;
  padding: 40px 0;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
}

#CONTACT .form-conform .Form-Item-Check {
  accent-color: #111111;
  /* 大きさ */
  inline-size: 1.2rem;
  block-size: 1.2rem;

  background: #fafafa;
  font-size: 16px;
  letter-spacing: 0.05em;
}

#CONTACT .form-conform input[type="checkbox"] {
  position: relative;
  top: 5px;
  margin-right: 5px; /*追加*/
}

#CONTACT .form-conform span.privacy {
  margin-left: 8px;
}

#CONTACT .form-conform span.privacy a {
  border-bottom: 1px solid;
  text-decoration: none;
}

#CONTACT .form-conform input.Form-Btn {
  margin: 0 auto;
  margin-top: 40px;
}

#CONTACT .form-conform input.Form-Btn:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

/* SP */

@media (max-width: 820px) {
  #CONTACT .ContentsContainer h2.title {
    font-size: 20px;
  }

  #CONTACT .Form-Item {
    max-width: 100%;
    display: block;
  }

  #CONTACT .Form-Item-Label {
    max-width: 100%;
    padding-bottom: 16px;
  }
  #CONTACT .Form-Item-Input {
    max-width: 100%;
    margin-left: 0;
  }
  #CONTACT .Form-Item-Radio {
    max-width: 100%;
    margin-left: 0;
  }
  #CONTACT .Form-Item .Form-Item-Textarea {
    max-width: 100%;
    margin-left: 0;
  }
}

/*============================
お問い合わせ完了ページ
============================*/
.contactSuccessPageTitle h1 {
  font-size: 32px;
  font-weight: bold;
  padding: 80px;
}

.contactSuccess p {
  font-size: 16px;
  padding: 20px;
}

.contactSuccess a {
  color: #111111;
}

@media (max-width: 820px) {
  .contactSuccessPageTitle h1 {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.1em;
    padding: 50px;
  }
}

/*============================
モーダル
============================*/
.logoutModal {
  position: absolute;
  top: 30%;
  left: 35%;
  right: 35%;
  bottom: 40%;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 30px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);
  min-height: 200px;
  min-width: 150px;
}

.OverlayModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.logoutModal .logoutModalButton {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.logoutModal h2 {
  text-align: left;
  font-size: 24px;
}

.logoutModal p {
  padding: 20px;
  font-size: 16px;
  text-align: left;
}

.logoutModal .logoutModalButton button {
  padding: 10px;
  margin: 20px;
  cursor: pointer;
  border: none;
  background-color: white;
  border: 2px solid #ff3f33;
  font-weight: bold;
  color: #ff3f33;
  width: 130px;
  height: 50px;
}

.logoutModal .logoutModalButton button:hover {
  background-color: #ff3f33;
  border: 2px solid #ff3f33;
  font-weight: bold;
  color: white;
}

@media (max-width: 820px) {
  .logoutModal {
    top: 20%;
    left: 5%;
    right: 5%;
    bottom: 50%;
  }
  .logoutModal p {
    padding: 10px;
    font-size: 12px;
    text-align: left;
  }
}

/*マイページ*/
.mypage h2 {
  padding: 20px 0;
  font-size: 24px;
  border-bottom: 5px solid #ff3f33;
  width: 400px;
  margin: 50px auto;
}

.mypageItem {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.mypageItem p {
  text-align: left;
  font-size: 16px;
}

.mypageItemTitle {
  width: 400px;
  border-bottom: 0.5px solid #959595;
}

.mypageItemTitle p {
  font-size: 20px;
}

.mypageItemContent {
  width: 400px;
}

.mypage .mypageButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0px;
  gap: 50px;
}

.mypage .mypageButton button {
  padding: 20px;
  cursor: pointer;
  border: none;
  background-color: white;
  border: 2px solid #ff3f33;
  font-weight: bold;
  color: #ff3f33;
  border-radius: 10px;
  width: 250px;
}

.mypage .mypageButton button:hover {
  background-color: #ff3f33;
  border: 2px solid #ff3f33;
  font-weight: bold;
  color: white;
}

@media (max-width: 820px) {
  .mypage h2 {
    font-size: 20px;
    width: 80%;
  }

  .mypageItemTitle {
    width: 80%;
    border-bottom: 0.5px solid #959595;
  }

  .mypageItemTitle p {
    font-size: 16px;
  }

  .mypageItemContent {
    width: 80%;
  }

  .mypage .mypageButton {
    margin: 30px;
    gap: 30px;
  }
  .mypage .mypageButton button {
    width: 150px;
  }
}

/*============================
404 ページ
============================*/
.error404PageTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error404PageTitle h1 {
  font-size: 32px;
  font-weight: bold;
  padding: 80px;
}

.error404PageText {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*============================
POLICY
============================*/

#POLICY .ContentsContainer {
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
  text-align: left;
  padding-top: 80px;
}

#POLICY .ContentsContainer .block {
  padding: 40px;
  background-color: #fbfbfb;
}

#POLICY .ContentsContainer .block h3 {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

#POLICY .ContentsContainer .block p.text {
  padding-top: 20px;
  font-size: 16px;
  letter-spacing: 0.05em;
}

#POLICY .ContentsContainer .block b.red {
  font-weight: bold;
  color: #ff3f33;
}

#POLICY .ContentsContainer .block ul.list li {
  margin-left: 20px;
  list-style: decimal;
  padding-top: 20px;
  font-size: 16px;
  letter-spacing: 0.05em;
}

#POLICY .ContentsContainer .block ol.abc-list li {
  margin-left: 20px;
  list-style: lower-alpha;
  padding-top: 20px;
  font-size: 16px;
  letter-spacing: 0.05em;
}

/*============================
応募ページ
============================*/
#Apply .ContentsContainer {
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
}

#Apply .ContentsContainer img {
  width: 50%;
  padding: 50px;
}

#Apply .ContentsContainer p.read-text {
  padding-top: 0px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.05em;
}

#Apply .ContentsContainer h2.companyName {
  padding-top: 80px;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.05em;
}

#Apply .ContentsContainer h2.title {
  padding-top: 200px;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

#Apply .ContentsContainer .noticeText {
  padding-top: 50px;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

#Apply .ContentsContainer .Form {
}

#Apply .Form-Item {
  width: 100%;
  max-width: 65.62%;
  margin: 50px auto;
  padding: 20px 0;
  display: flex;
}

#Apply .Form-Item .RequiredText {
  background-color: #ff3f33;
  text-align: center;
  color: white;
  padding: 5px;
  border-radius: 5px;
  margin-left: 20px;
  font-size: 16px;
  font-weight: 500;
}

#Apply .Form-Item .OptionalText {
  background-color: #959595;
  text-align: center;
  color: white;
  padding: 5px;
  border-radius: 5px;
  margin-left: 20px;
  font-size: 16px;
  font-weight: 500;
}
#Apply .Form-Item-Label {
  width: 100%;
  max-width: 248px;
  letter-spacing: 0.05em;
  font-weight: bold;
  font-size: 16px;
}

#Apply .Form-Item-Text {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.05em;
  flex: 1;
  width: 100%;
}

#Apply .Form-Item-Input {
  border: 1px solid #111111;
  border-radius: 6px;
  margin-left: 40px;
  padding-left: 1em;
  padding-right: 1em;
  padding: 0 1em;
  height: 48px;
  flex: 1;
  width: 100%;
  max-width: 410px;
  background: #fafafa;
  font-size: 16px;
  letter-spacing: 0.05em;
  cursor: pointer;
}

#Apply .Form-Item-Checkbox {
  margin: 0 auto;
}

#Apply input[type="checkbox"] {
  margin: 10px;
}

#Apply .Form-Item .Form-Item-Textarea {
  border: 1px solid #111111;
  border-radius: 6px;
  margin-left: 40px;
  padding-left: 1em;
  padding-right: 1em;
  padding: 1em;
  height: 216px;
  flex: 1;
  width: 100%;
  max-width: 410px;
  background: #fafafa;
  font-size: 16px;
  letter-spacing: 0.05em;
}

#Apply .Form-Item-File {
  margin-left: 10px;
  height: 60px;
  flex: 1;
  width: 100%;
  max-width: 410px;
  font-size: 16px;
  letter-spacing: 0.05em;
}
#Apply .Form-Item-FileSelect {
  cursor: pointer;
  background-color: #959595;
  color: white;
  padding: 10px;
  border-radius: 20px;
}

#Apply .Form-Item-FileSelect-notAllowed {
  cursor: not-allowed;
  background-color: #959595;
  color: white;
  padding: 10px;
  border-radius: 20px;
}

#Apply input[type="file"] {
  display: none;
}

#Apply .Form-Item-FileName {
  margin-top: 20px;
}

#Apply input {
  box-sizing: border-box;
  cursor: pointer;
}

#Apply .Form-Item button {
  width: 280px;
  margin: 0 auto;
  border-radius: 4px;
  border: none;
  outline: none;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.15em;
  background: #ff3f33;
  color: #fff;
  cursor: pointer;
}

#Apply .Form-Item a {
  margin: 0 auto;
}

#Apply .Form-Item button:disabled {
  background: lightgray;
}

#Apply p.read-text {
  padding-top: 50px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.05em;
}

#Apply p.read-text span.read-text-highlight {
  font-weight: bold;
  font-size: 20px;
  padding: 0px 10px;
  border-bottom: #ff3f33 1px solid;
}

/* SP */

@media (max-width: 820px) {
  #Apply .ContentsContainer h2.companyName {
    font-size: 20px;
  }

  #Apply .ContentsContainer h2.title {
    padding-top: 100px;
    font-size: 20px;
  }

  #Apply .Form-Item {
    max-width: 100%;
    display: block;
  }

  #Apply .Form-Item-File {
    max-width: 100%;
    display: block;
    margin-left: 0px;
  }

  #Apply .Form-Item-Label {
    max-width: 100%;
    padding-bottom: 16px;
  }
  #Apply .Form-Item-Input {
    max-width: 100%;
    margin-left: 0;
  }
  #Apply .Form-Item-Radio {
    max-width: 100%;
    margin-left: 0;
  }
  #Apply .Form-Item .Form-Item-Textarea {
    max-width: 90%;
    margin-left: 0;
  }
}

/*============================
応募確認ページ
============================*/
#ApplyConfirm .ContentsContainer {
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
}

#ApplyConfirm .ContentsContainer p.read-text {
  padding-top: 50px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.05em;
}

#ApplyConfirm .ContentsContainer h2.title {
  padding-top: 200px;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

#ApplyConfirm .ContentsContainer .Form {
  margin-top: 100px;
}

#ApplyConfirm .Form-Item {
  width: 100%;
  max-width: 65.62%;
  margin: 50px auto;
  padding: 20px 0;
  display: flex;
}
#ApplyConfirm .Form-Item-Label {
  width: 100%;
  max-width: 248px;
  letter-spacing: 0.05em;
  font-weight: bold;
  font-size: 16px;
}

#ApplyConfirm .Form-Item-Text {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.05em;
  flex: 1;
  width: 100%;
  border-bottom: 1px solid;
}

#ApplyConfirm .Form-Item-TextArea {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.05em;
  flex: 1;
  width: 100%;
  border: none;
  border-radius: 5px;
  margin-left: 30px;
  text-align: left;
}

#ApplyConfirm .Form-Item-File {
  margin-left: 10px;
  height: 60px;
  flex: 1;
  width: 100%;
  max-width: 410px;
  font-size: 16px;
  letter-spacing: 0.05em;
}
#ApplyConfirm .Form-Item-FileSelect {
  cursor: pointer;
  background-color: #ff3f33;
  color: white;
  padding: 10px;
  border-radius: 20px;
}
#ApplyConfirm input[type="file"] {
  display: none;
}

#ApplyConfirm .Form-Item-FileName {
  margin-top: 20px;
}

#ApplyConfirm .Form-Item button {
  width: 280px;
  margin: 0 auto;
  border-radius: 4px;
  border: none;
  outline: none;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.15em;
  background: #ff3f33;
  color: #fff;
  cursor: pointer;
}

#ApplyConfirm .Form-Item a {
  margin: 0 auto;
}

#ApplyConfirm .Form-Item button:disabled {
  background: lightgray;
}

/* SP */

@media (max-width: 820px) {
  #ApplyConfirm .ContentsContainer h2.companyName {
    font-size: 20px;
  }

  #ApplyConfirm .ContentsContainer h2.title {
    padding-top: 100px;
    font-size: 20px;
  }

  #ApplyConfirm .Form-Item {
    max-width: 100%;
    display: block;
  }

  #ApplyConfirm .Form-Item-TextArea {
    margin-left: 0px;
    text-align: center;
  }

  #ApplyConfirm .Form-Item-File {
    max-width: 100%;
    display: block;
    margin-left: 0px;
  }

  #ApplyConfirm .Form-Item-Label {
    max-width: 100%;
    padding-bottom: 16px;
  }
  #ApplyConfirm .Form-Item-Input {
    max-width: 100%;
    margin-left: 0;
  }
  #ApplyConfirm .Form-Item-Radio {
    max-width: 100%;
    margin-left: 0;
  }
  #ApplyConfirm .Form-Item .Form-Item-Textarea {
    max-width: 100%;
    margin-left: 0;
  }
}

/*============================
応募完了ページ
============================*/
.ApplySuccessPage {
  width: 90%;
  margin: 0 auto;
  padding-top: 80px;
}

.ApplySuccessPage h1 {
  font-size: 32px;
  font-weight: bold;
  padding: 80px;
}

.ApplySuccessPage .scheduleContainer {
  padding-top: 50px;
  padding-bottom: 50px;
}

.ApplySuccessPage .scheduleContainer h2 {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.1em;
  padding: 20px;
}

.ApplySuccessPage .schedule {
  width: 100%;
  max-width: 50%;
  margin: 10px auto;
  justify-content: center;
  align-items: center;
  display: flex;
}
.ApplySuccessPage .schedule-label {
  width: 100%;
  max-width: 50%;
  letter-spacing: 0.05em;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
}
.ApplySuccessPage .schedule-item {
  width: 100%;
  max-width: 50%;
  font-size: 16px;
  letter-spacing: 0.05em;
  text-align: left;
}

.ApplySuccessPage p {
  margin-bottom: 50px;
  font-size: 16px;
}

.ApplySuccessPage a {
  color: black;
  background-color: black;
  color: white;
  padding: 20px 80px 20px 80px;
  text-decoration: none;
}

.ApplySuccessPage a:hover {
  opacity: 0.3;
}

@media (max-width: 820px) {
  .ApplySuccessPage h1 {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.1em;
    padding: 30px;
  }
  .ApplySuccessPage p {
    font-size: 14px;
  }
  .ApplySuccessPage .schedule {
    max-width: 90%;
    gap: 10px;
  }
  .ApplySuccessPage .scheduleContainer h2 {
    font-size: 20px;
  }
  .ApplySuccessPage .schedule-label {
    font-size: 14px;
  }
  .ApplySuccessPage .schedule-item {
    font-size: 14px;
  }
}

/*============================
応募先選択ページ
============================*/

#APPLY .ApplyContents {
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
  padding-top: 20px;
}

#APPLY .ContentsContainer p.read-text {
  padding-top: 50px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.05em;
}

#APPLY .ContentsContainer .scheduleContainer {
  padding-top: 20px;
}

#APPLY .ContentsContainer .scheduleContainer h2 {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.1em;
  padding: 10px;
}

#APPLY .ContentsContainer .scheduleTable {
  border-collapse: collapse;
  width: 90%;
  max-width: 700px;
  margin: 0 auto;
  background-color: #fbfbfb;
  border-radius: 12px;
}

#APPLY .ContentsContainer .scheduleTable th,
#APPLY .ContentsContainer .scheduleTable td {
  padding: 0.5em;
  text-align: left;
  font-size: 14px;
  padding: 20px;
}

#APPLY .ContentsContainer .scheduleTable th {
  font-weight: bold;
  width: 40%;
  position: relative;
  font-size: 12px;
}

#APPLY .ContentsContainer .scheduleTable th::after {
  content: "";
  background-color: #c1c7c6;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  width: 1px;
  height: 60%;
}

#APPLY .ApplyContents ul.list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

#APPLY .ApplyContents ul.list li.articleblock {
  width: 46%;
  padding: 40px 0 40px 0;
}

#APPLY .ApplyContents ul.list li.articleblock a.link {
  display: block;
  border-radius: 12px;
  padding: 40px;
  text-align: left;
  background-color: #ffffff;
  border: 1px solid #dbdbdb;
  color: #333333;
  height: 230px;
  text-decoration: none;
}
#APPLY .ApplyContents ul.list li.articleblock a.link:hover {
  opacity: 0.6;
  /* background-color: #333333;
  color: #ffffff; */
}

#APPLY .ApplyContents ul.list li.articleblock .logo {
  width: 80%;
  margin: 0 auto;
}

#APPLY .ApplyContents ul.list li.articleblock .logo img {
  height: 120px;
  margin: 0px auto;
  max-height: 120px;
  object-fit: contain;
}

#APPLY .ApplyContents ul.list li.articleblock a.link h2.title {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

#APPLY .ApplyContents ul.list li.articleblock a.link p.text {
  padding-top: 20px;
  font-size: 14px;
  letter-spacing: 0.05em;
}

#APPLY .ApplyContents .projectStatus {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
  position: absolute;
  bottom: 50px;
  left: 10px;
  text-align: center;
}

#APPLY .ApplyContents .projectStatus p {
  padding: 5px;
  border: 1px solid;
  color: #959595;
  font-size: 11px;
}

#APPLY .ApplyContents .projectStatus p.active {
  color: #ffffff;
  background-color: #ff3f33;
  border: 1px solid #ff3f33;
}

#APPLY .ApplyContents .projectStatus p.in-active {
  color: #ffffff;
  background-color: #959595;
  border: 1px solid #959595;
}

@media (max-width: 820px) {
  #APPLY .ApplyContents ul.list {
    display: block;
  }

  #APPLY .ContentsContainer p.read-text {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.05em;
    width: 90%;
    margin: 0 auto;
  }

  #APPLY .ApplyContents ul.list li.articleblock {
    width: auto;
  }

  #APPLY .ApplyContents ul.list li.articleblock a.link {
    height: 300px;
  }
  #APPLY .ApplyContents .projectStatus {
    flex-wrap: wrap;
    justify-content: center;
    left: 0;
  }

  #APPLY .ApplyContents .projectStatus p {
    width: 40%;
  }
  #APPLY .ContentsContainer .scheduleContainer h2 {
    font-size: 16px;
  }

  #APPLY .ContentsContainer .scheduleTable th,
  #APPLY .ContentsContainer .scheduleTable td {
    font-size: 10px;
    padding: 10px;
  }
}
